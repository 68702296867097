<template>
  <div class="reviews-stars flex">
    <template v-for="(i, index) in bestRating">
      <svg
        :key="index"
        class="reviews-stars__star relative mr-0.5 xxs:mr-2"
        :class="{
          'mr-2':!small,
          'mr-1':small,
          'reviews-stars__star--full': index < ~~rating,
          'reviews-stars__star--half': index < rating && index === ~~rating,
          'reviews-stars__star--empty': index >= rating,
          'hidden':index >= rating && hideOverRating
        }"
        :width="small ? 16 : 20"
        :height="small ? 16 : 20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fill-rule="evenodd">
          <rect
            class="reviews-stars__star__background"
            fill="#f06b1f"
            width="20"
            height="20"
            rx="2"
          />
          <path
            class="reviews-stars__star__icon"
            fill="#fff"
            d="m10 12.614-3.919 2.78 1.433-4.586L3.66 7.94l4.804-.055L10 3.333l1.536 4.552 4.804.055-3.854 2.868 1.433 4.585z"
          />
          <rect
            width="10"
            height="20"
            rx="2"
          />
          <rect
            v-if="index < rating && index === ~~rating"
            width="10"
            height="20"
            x="10"
            fill="#f06b1f"
          />
        </g>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    bestRating: {
      type: Number,
      default: () => 5,
    },
    rating: {
      type: Number,
      default: () => Math.PI,
      required: true,
    },
    small: {
      type: Boolean,
      defaul: () => false,
      required: false,
    },
    hideOverRating: {
      type: Boolean,
      default: () => true,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.reviews-stars {
  &__star {
    $self: &;
    &__background {
      fill: #f06b1f;
    }
    &__icon {
      fill: #fff;
    }
    &__foreground {
      fill: #f06b1f;
    }
    &--empty {
      #{$self}__icon {
        @apply hidden;
      }
    }
    &--full {
      #{$self}__foreground {
        @apply hidden;
      }
    }
  }
}
</style>
